import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
    { title: 'Outbox', url: '/folder/Outbox', icon: 'paper-plane' },
    { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
    { title: 'Archived', url: '/folder/Archived', icon: 'archive' },
    { title: 'Trash', url: '/folder/Trash', icon: 'trash' },
    { title: 'Spam', url: '/folder/Spam', icon: 'warning' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  private loading: HTMLIonLoadingElement;

  constructor(public auth: AngularFireAuth, private loadingCtrl: LoadingController) {}

  async ngOnInit() {
    this.login();
    this.loading = await this.loadingCtrl.create({
      message: 'Logging In',
      spinner: 'circles',
    });
    this.loading.present();
  }

  async login() {
    console.log('Login Triggered');
    try {
      const user = await this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
      console.log('User', user.user);
      // For man cutom access claims
      const idTokenResult = await firebase.auth().currentUser.getIdTokenResult();
      console.log('idTokenResult', idTokenResult);
      this.loading.dismiss();
      // Confirm the user is an Admin.
     if (!!idTokenResult.claims.admin) {
      // Show admin UI.
    } else {
      // Show regular user UI.
    }
    } catch (error) {
      this.loading.dismiss();
      console.error('Login Error', error);
    }
  }
  logout() {
    this.auth.signOut();
  }
}
